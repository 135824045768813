

























import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    StatusBar,
  },
})
export default class Feedback extends Mixins(Mixin) {
  onClickRight(): void {
    console.log(555);
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  /*
   *意见
   */
  goOpinion(): void {
    this.$router.push(`/personal-data/opinion`);
  }
  /*
   *投诉
   */
  goComplain(): void {
    this.$router.push(`/personal-data/complain`);
  }
  /*
   *注销
   */
  goLogout(): void {
    this.$router.push(`/personal-data/logout`);
  }
}
