




















































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";
import Certificate from "./Certificate.vue";
import PhoneNumberBond from "./Component/PhoneNumberBond.vue";
import { UserModule } from "@/store/modules/user";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    StatusBar,
    Certificate,
    PhoneNumberBond,
  },
})
export default class ChangePhone extends Mixins(Mixin) {
  phoneNumberChange = "";
  phoneNumber = "";
  public phone = "";
  public code = "";
  public isChange = false;
  public phoneCode = 86;
  public VerificationCode = "";
  public info = {
    show: false,
  };
  /*
   *弹窗
   */
  changeCode(): void {
    this.info.show = true;
  }
  /*
   *选择code
   */
  choose(data: { name: string; id: number }): void {
    this.phoneCode = data.id;
  }
  onClickRight(): void {
    console.log(555);
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  phoneChange(val: string): void {
    this.phoneNumber = val;
  }
  phoneCodeChange(val: string): void {
    this.VerificationCode = val;
  }
  /*
   *按钮
   */
  gotoLogin(): void {
    UserModule.LOGOUT_PERSIST();
    this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({ router: this.$router });
  }
  //下一步
  submit(): void {
    this.$api.memberApi.setting.updatePhone(
      {
        /** 验证码 */
        captcha: this.VerificationCode,

        /** 新手机号 */
        phone: this.phoneNumber,
      },
      ({ data }) => {
        if (this.isChange == false) {
          this.isChange = true;
          let reg = /(\d{3})\d{4}(\d{4})/;
          this.phoneNumberChange = UserModule.phone.replace(reg, "$1****$2");
        }
      }
    );
  }
}
