
























































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    StatusBar,
  },
})
export default class Logout extends Mixins(Mixin) {
  public check = false;
  public list = [
    { name: "（一）当前账号为有效状态", id: 1 },
    { name: "（二）当前账号内无未完成状态订单", id: 2 },
    { name: "（三）余额内无资产", id: 3 },
  ];
  go(): void {
    console.log(987);
  }
  onClickRight(): void {
    console.log(555);
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  submit(): void {
    if (this.check) {
      console.log("注销");
    }
  }
}
