
















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Image, NavBar, Sticky } from "vant";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [NavBar.name]: NavBar,
    [Sticky.name]: Sticky,
  },
})
export default class MyNavBar extends Mixins(Mixin) {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: false }) public component!: boolean;

  onClickLeft(): void {
    if (this.component) {
      this.$emit("clickLeft");
    } else {
      if (this.AppSource == "harmonyOS") {
        this.jsBridge("back", "", () => {
          this.$router.go(-1);
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
  onClickRight() {
    this.$emit("clickRight");
  }
}
