


















import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
// import StatusBar from "@/components/StatusBar.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    // StatusBar,
  },
})
export default class Notice extends Mixins(Mixin) {
  public title: string | (string | null)[] = "";
  public type: string | (string | null)[] = "";
  public content = "";

  mounted(): void {
    this.title = this.$route.query.name;
    this.type = this.$route.query.type;
    this.getInfo();
  }

  onClickLeft(): void {
    this.$emit("back");
  }
  getInfo(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.$api.cmsApi.commonInfo.policy(this.type as any, ({ data }) => {
      this.content = data;
    });
  }
}
