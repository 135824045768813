






















































































































































import {
  Component,
  Vue,
  Prop,
  Watch,
  Emit,
  Mixins,
} from "vue-property-decorator";
import {
  Popup,
  Cell,
  CellGroup,
  Image,
  Icon,
  Field,
  Row,
  Col,
  Area,
  DatetimePicker,
  Button,
  Toast,
  Uploader,
  Picker,
} from "vant";
import { areaList } from "@vant/area-data";
import * as imageConversion from "image-conversion";
import { UserModule } from "@/store/modules/user";
import { Mixin } from "@/core/mixins/mixin";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [Area.name]: Area,
    [DatetimePicker.name]: DatetimePicker,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Picker.name]: Picker,
  },
})
export default class PersonalPopup extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: {
      show: false,
      title: "",
    },
  })
  public info!: { show: boolean; title: string };
  @Prop({
    type: Object,
    default: {},
  })
  public userInfo!: member.UserInformationRes;
  @Prop()
  public hobbyList!: dictionary.Codebook[];
  @Emit("choose")
  select(): void {
    return;
  }
  public type = "";
  public areaList = areaList;
  public city = [];
  public height = "30%";
  public currentDate = new Date(1990, 0, 1);
  public minDate = new Date(1950, 1, 1);
  public maxDate = new Date(2100, 12, 31);
  public birthday = "";
  public list: dictionary.Codebook[] = [];
  submitList: dictionary.Codebook[] = [];
  @Watch("userInfo", { immediate: true })
  userInfoChange(value: member.UserInformationRes): void {
    this.currentDate = UserModule.isRealName
      ? new Date(value.birthday!)
      : new Date(1990, 0, 1);
    this.$nextTick(() => {
      if (value.hobby) {
        value.hobby.forEach((item) => {
          this.hobbyList.forEach((items: dictionary.Codebook) => {
            if (item == items.name) {
              this.chooseHobby(items);
            }
          });
        });
      }
    });
  }
  get title(): string {
    let name = "";
    this.type = this.info.title;
    if (this.type == "head") {
      this.height = "30%";
      name = "设置头像";
    } else if (this.type == "sex") {
      this.height = "30%";
      name = "请选择性别";
    } else if (this.type == "birthday") {
      name = "请选择您的生日";
      this.height = "40%";
    } else if (this.type == "city") {
      name = "城市";
      this.height = "40%";
    } else if (this.type == "hobby") {
      name = "添加兴趣";
      this.height = "40%";
    }

    return name;
  }

  // 上传图片
  afterRead(file: { file: File }): void {
    let url;
    //将图片拿过去裁剪
    url = window.URL.createObjectURL(file.file);
    UserModule.USERIMG_PERSIST(url);
    this.fileUpload();
  }

  close(): void {
    this.info.show = false;
  }
  chooseHead(): void {
    return;
  }
  /*
   *选择相机
   */
  uploadOne(): void {
    let file = document.getElementById("upfile-one");
    this.fileUpload();
  }
  /*
   *选择相册
   */
  uploadTwo(e: File): void {
    let inputDOM = this.$refs.inputer as HTMLFormElement;
    let file = inputDOM.files[0]; // 通过DOM取文件数据
    let url = window.URL.createObjectURL(file);
    UserModule.USERIMG_PERSIST(url);
    this.fileUpload();
  }
  fileUpload(): void {
    this.$router.push(`/personal-data/edit-photo`);
  }
  /*
   *选择性别
   */
  chooseSex(val: "SEX_BOY" | "SEX_GIRL", sex: string): void {
    let data = {
      type: this.type,
      value: sex,
      list: [],
    };
    this.$api.memberApi.setting.updateUserInfo(
      {
        sexEnum: val,
      },
      ({ data }) => {
        this.$emit("sexChoose", data);
        // this.select();
      }
    );
    this.info.show = false;
  }
  /*
   *城市
   */
  changeArea(val: any): void {
    this.city = val.getValues();
    // this.select(val);
    // this.city = val.getValues()[val.getValues().length - 1].name;
  }
  /*
   *获取日期
   */
  getTime(e: any): void {
    let times = e.getValues();
    this.birthday = `${times[0]}-${times[1]}-${times[2]}`;
  }
  /*
   *选择爱好
   */
  chooseHobby(item: dictionary.Codebook): void {
    let index = this.list.indexOf(item);
    if (index == -1) {
      this.list.push(item);
    } else {
      this.list.splice(index, 1);
    }
  }

  /*
   *确认
   */
  submit(): void {
    let data = {
      type: this.type,
      value: "",
      list: [{ name: "", id: 0, describe: "" }],
    };
    if (this.type == "city") {
      if (this.city.length != 0) {
        this.city = this.city;
      } else {
        this.city[0] = this.DictionaryModule.twoAddress[0] as never;
        this.city[1] = (this.DictionaryModule.twoAddress[0] as any)
          .children[0] as never;
      }
      this.$api.memberApi.setting.updateUserInfo(
        {
          currentCityID: (this.city[1] as any).id,
          currentProvinceID: (this.city[0] as any).id,
        },
        ({ data }) => {
          // this.select();
          this.$emit("sexChoose", data);
        }
      );
    } else if (this.type == "birthday") {
      data.value = this.birthday ? this.birthday : "1990-01-01";
      this.$api.memberApi.setting.updateUserInfo(
        {
          birthday: data.value,
        },
        ({ data }) => {
          monitorEvent("MyData_SetBirthSuc", "成功设置生日"); // 埋点：个人资料页，成功设置生日
          // this.select();
          this.$emit("sexChoose", data);

          if (data.realName) {
          } else {
            if (!data.birthdaySet) {
              this.$toast("生日只可修改一次");
            }
          }
          return;
        }
      );
    } else if (this.type == "hobby") {
      this.submitList = this.list.map((item) => {
        return item.name as dictionary.Codebook;
      });
      data.list = this.list as {
        name: string;
        id: number;
        describe: string;
      }[];
      this.$api.memberApi.setting.updateUserInfo(
        {
          hobbyEnums: this.submitList as string[],
        },
        ({ data }) => {
          // this.select();
          this.$emit("sexChoose", data);

          return;
        }
      );
    }
    this.info.show = false;
  }

  // nowTime(): string {
  //   let y = new Date().getFullYear();
  //   let m = new Date().getMonth() + 1;
  //   let d = new Date().getDate();
  //   return `${y}-${this.fillZero(m)}-${this.fillZero(d)}`;
  // }
  /*
   * 补零处理
   */
  fillZero(num: number): string {
    let value = num < 10 ? "0" + num.toString() : num.toString();
    return value;
  }
}
