import { http } from "@/plugins/axios";
import { Toast } from "vant";

export default function uploadFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    http.defaults.headers.post["Content-Type"] = "multipart/form-data";
    http({
      method: "post",
      url: "oss/file",
      data: formData,
    })
      .then((res: any) => {
        if (res.code === "200") {
          resolve(res.data as string);
        } else {
          reject(res);
          Toast(res.errors[0]);
        }
      })
      .catch((err) => {
        Toast("上传失败");
        reject(err);
      });
  });
}
