







































































































































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Icon,
  Row,
  Col,
  Button,
  Form,
  Toast,
} from "vant";
import StatusBar from "@/components/StatusBar.vue";
import Certificate from "./Certificate.vue";
import RealMobileBond from "./Component/RealMobileBond.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Form.name]: Form,
    StatusBar,
    Certificate,
    RealMobileBond,
  },
})
export default class RealName extends Mixins(Mixin) {
  isDisable = false;
  typeName: string | undefined = "身份证";
  phoneCode = "";
  public cardType:
    | "ID_CERT"
    | "TAIWAN_CERT"
    | "PASSPORT_CERT"
    | "SOLDIERS_CERT"
    | "OFFICERS_CERT" = "ID_CERT";
  public name: string | undefined = "";
  public cardId: string | undefined = "";
  public girlList = [
    { url: "r1.png", name: "账号更安全" },
    { url: "r2.png", name: "更多专享福利" },
    { url: "r3.png", name: "抢票更便捷" },
  ];
  public info = {
    show: false,
  };
  public type = "";

  mounted(): void {
    this.type = this.$route.params.type;
    if (this.UserModule.isRealName) {
      this.$api.memberApi.setting.getIdentifyInfo(({ data }) => {
        this.name = data.name;
        this.cardId = data.certNumber;
        this.typeName = data.certTypeEnumInfo?.describe;
      });
    }
    // let Certificate = this.$refs.certificate as Certificate;
    // if (Certificate) Certificate.submit();
  }

  /*
   *选择类型
   */
  choose(data: {
    name:
      | "ID_CERT"
      | "TAIWAN_CERT"
      | "PASSPORT_CERT"
      | "SOLDIERS_CERT"
      | "OFFICERS_CERT";
    describe: string;
  }): void {
    this.cardType = data.name;
    this.typeName = data.describe;
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }

  gotoRealName(): void {
    (this.$refs.realname as HTMLFormElement)
      .validate()
      .then(() => {
        //接口查询较慢，防止用户多次点击
        this.isDisable = true;
        this.$toast("认证中，请稍后...");
        setTimeout(() => {
          this.isDisable = false; //点击一次时隔两秒后才能再次点击
        }, 5000);
        // 验证通过
        if (this.phoneCode) {
          this.$api.memberApi.setting.saveIdentify(
            {
              certNumber: this.cardId,
              certType: this.cardType,
              code: this.phoneCode,
              name: this.name,
            },
            ({ data }) => {
              Toast.success("认证成功");
              this.UserModule.REALNAME_PERSIST(true);
              if (this.type === "1") {
                this.$router.go(-1);
              }
              if (this.type === "2") {
                this.$router.push(`/set-password/${data}`);
              }
            }
          );
        } else {
          this.$toast("请获取验证码");
        }
      })
      .catch((b: []) => {
        //验证失败
      });
  }
  pullCode(val: string): void {
    this.phoneCode = val;
  }
}
