












import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import CouponList from "@/views/Mine/AssetCenter/Coupon/OrderCouponList.vue";
@Component({
  components: {
    CouponList,
  },
})
export default class ExternalMyCouponsPage extends Mixins(Mixin) {
  public unusableCouponList: Array<good.CouponResDto> = [];
  public usableCouponList: Array<good.CouponResDto> = [];
  public checkCoupon: ObjectMap = {}; //选中优惠券
  public categoryId = "";
  public actuallyPaidAmt = 0;
  public productId = "";
  public showId = "";
  mounted() {
    let route = this.$route as any;
    this.categoryId = route.query.categoryId;
    this.actuallyPaidAmt = route.query.actuallyPaidAmt;
    this.productId = route.query.productId;
    this.showId = route.query.showId;
    this.checkCoupon.couponDetailId = route.query.couponDetailId;
    this.getCounpon();
  }
  getCounpon(): void {
    this.$api.goodApi.coupon.coupons(
      {
        categoryId: this.categoryId,
        orderTotalAmount: this.actuallyPaidAmt,
        productId: Number(this.productId),
        showId: Number(this.showId),
      },
      (data) => {
        this.unusableCouponList = data.data.unusableCouponList!;
        this.usableCouponList = data.data.usableCouponList!;
      }
    );
  }
  checkItem(item: Record<string, unknown>): void {
    // todo
    this.checkCoupon = item;
  }
  back(): void {
    this.jsBridge("back", "", () => {
      this.$router.go(-1);
    });
  }
}
