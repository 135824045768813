












































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Sticky, Icon } from "vant";

@Component({
  components: {
    [Sticky.name]: Sticky,
    [Icon.name]: Icon,
  },
})
export default class Navbar extends Mixins(Mixin) {
  @Prop({ default: 80 }) public scrollTop!: number;
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: true }) public showTitle!: string;
  @Prop({ default: true }) public showTicketTitle!: string;
  @Prop({ default: false }) public showLeftClose!: string;

  @Prop({ default: true }) public showLeft!: string;
  @Prop({ default: true }) public showRight!: string;
  // 左侧返回按钮样式控制，默认字体图标，whiteIcon为true则是灰色圆形加白色箭头
  @Prop({ default: false }) public whiteIcon!: string;
  @Prop({ default: false }) public showbackHome!: boolean;

  showHead = false;

  // 顶部导航滑动变化
  scrollPage(callback: { scrollTop: number }): void {
    if (callback.scrollTop < this.scrollTop) {
      this.showHead = false;
    } else {
      this.showHead = true;
    }
  }
  clickRight(): void {
    this.$emit("clickRight");
  }
  goBack(): void {
    this.$emit("go-back");
  }

  backhome(): void {
    this.$router.push("/home");
  }
}
