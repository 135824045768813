





















import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";
import NavTitle from "@/components/NavBar.vue";
import { UserModule } from "@/store/modules/user";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    StatusBar,
    NavTitle,
  },
})
export default class EditorName extends Mixins(Mixin) {
  value = "";

  mounted(): void {
    let userName = this.$route.query.username;
    this.value = userName ? String(userName) : "";
  }

  onClickRight(): void {
    let value = this.value.trim();
    if (value) {
      this.$api.memberApi.setting.updateUserInfo(
        { nickName: this.value as string },
        ({ data }) => {
          if (data.nickName) {
            UserModule.NICKNAME_CHANGE(data.nickName);
          }
          this.$router.go(-1);
        }
      );
    } else {
      this.$toast("昵称不能为空");
    }
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }
}
