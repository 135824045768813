




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";

import Notice from "@/views/Mine/Set/About/components/Notice.vue";
@Component({
  components: {
    Notice,
  },
})
export default class ExternalAccessNoticePage extends Mixins(Mixin) {
  back(): void {
    if (this.ChannelModule.channel == "plat_h5") {
      this.$router.go(-1);
    } else {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    }
  }
}
